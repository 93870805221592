import React from 'react';
import Helmet from 'react-helmet';
import Offerings from '../Offerings';
import PropTypes from 'prop-types';

const PoliciesPageTemplate = (props) => {
  const {
    title,
    heading,
    description,
    policies,
    meta_title,
    meta_description,
  } = props;

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
      </Helmet>
      <section className="hero is-primary is-bold is-medium">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title">{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div>
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                  <Offerings gridItems={policies.details} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
PoliciesPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  policies: PropTypes.shape({
    details: PropTypes.array,
  }),
};

export default PoliciesPageTemplate;
